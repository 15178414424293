import React, { useEffect, useState } from "react"
import empowerLogo from "../images/empower25-logo.png"
import arrowIcon from "../images/arrow.png"
import empowerRobot from "../images/empower-robot-image.png"
import QCGPrimaryLogo from "../images/QCG-Primary-logo-_1_.png"
import empowerBubble from "../images/Speech bubble mobine mockup.png"

import "./empower2025.css"

function DesignElements() {
  return (
    <>
      <div className="empower-2025-design-element-wrapper"></div>
    </>
  )
}

function Empower2025Header() {
  return (
    <>
      <div className="empower-2025-logo">
        <img
          src={empowerLogo}
          alt="Empower 2025"
          width={330}
          height={130}
          id="empower-2025"
          className="w-full h-full"
        />
      </div>
    </>
  )
}

function Empower2025Info() {
  return (
    <>
      <div className="empower-2025-info">
        <div
          class="empower-bubble-container"
          style={{ transform: "translate(25px, -10px)" }}
        >
          <img
            src={empowerBubble}
            alt="Empower Bubble"
            width={320}
            height={320}
            id="empower-bubble"
            style={{
              width: "100% !important",
            }}
          />
          <div className="empower-info-text">
            <h3>Hi, I'm Louie, your Empower 2025 agent!</h3>
            <p>
              I'm here to help you navigate the conference, and make the most of
              your experience.
            </p>
          </div>
        </div>
        <div className="empower-info">
          <img
            src={empowerRobot}
            alt="Empower Robot"
            width={220}
            height={220}
            id="empower-robot"
          />
        </div>
      </div>
    </>
  )
}

function Empower2025Embed() {
  const [embedHtml, setEmbedHtml] = useState(null)

  // useEffect(() => {
  //   fetch("https://embed.cody.bot/9e4c62ec-a222-483d-9e10-68c7c3f5240b")
  //     .then(response => response.text())
  //     .then(html => setEmbedHtml(html))
  //     .catch(error => console.error("Error loading Cody AI:", error))
  // }, [])

  return (
    <div
      style={{
        maxWidth: "350px",
        margin: "0 auto",
      }}
    >
      <iframe
        src="https://embed.cody.bot/9e4c62ec-a222-483d-9e10-68c7c3f5240b"
        name="codyai"
        scrolling="no"
        frameborder="1"
        marginheight="0"
        marginwidth="0"
        height="550px"
        width="100%"
        allowfullscreen
        style={{ maxHeight: "550px !important" }}
      ></iframe>
    </div>
  )
}

function Empower2025Footer() {
  return (
    <>
      <div className="empower-2025-footer">
        <h3>BROUGHT TO YOU BY</h3>
        <a href="/" className="footer-empower-logo">
          <img
            src={QCGPrimaryLogo}
            alt="QCG Primary Logo"
            width={2458}
            height={564}
          />
        </a>
      </div>
    </>
  )
}

function Empower2025() {
  return (
    <div className="empower-2025-container">
      <DesignElements />
      <Empower2025Header />
      <Empower2025Info />
      <Empower2025Embed />
      <Empower2025Footer />
    </div>
  )
}

export default Empower2025
